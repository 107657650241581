<template>
  <v-app class="container-params">
    <h2 class="title-params">Parametros del usuario</h2>
    <div class="inverse-layout">
      <div class="divParams">
        <div class="params-list-div">
          <v-content>
            <v-container fluid>
              <v-row align-content="stretch">
                <v-col>
                  <v-list three-line class="list1">
                    <drop-list
                      :items="items1"
                      @reorder="$event.apply(items1)"
                      @insert="insert1"
                      mode="cut"
                    >
                      <template v-slot:item="{ item, reorder }">
                        <drag
                          :key="item.title"
                          :data="item"
                          @cut="remove(items1, item)"
                        >
                          <v-list-item
                            style="background-color: white"
                            :style="
                              reorder
                                ? {
                                    borderLeft: '2px solid #1976D2',
                                    marginLeft: '-2px',
                                  }
                                : {}
                            "
                          >
                            <v-list-item-avatar>
                              <v-img src="@/assets/cure_logo_mini_01.png" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title v-html="item.title" />
                              <v-list-item-subtitle v-html="item.subtitle" />
                            </v-list-item-content>
                            <v-icon color="yellow" large> mdi-pencil </v-icon>
                            <v-icon color="red" large> mdi-delete </v-icon>
                          </v-list-item>
                          <v-divider />
                        </drag>
                      </template>
                      <template v-slot:inserting-drag-image="{ data }">
                        <v-list-item-avatar
                          style="transform: translate(-50%, -50%) scale(1.5)"
                        >
                          <img :src="data.avatar" />
                        </v-list-item-avatar>
                      </template>
                      <template v-slot:reordering-drag-image />
                      <template v-slot:feedback="{ data }">
                        <v-skeleton-loader
                          type="list-item-avatar-three-line"
                          :key="data.title"
                          style="
                            border-left: 2px solid #1976d2;
                            margin-left: -2px;
                          "
                        />
                      </template>
                    </drop-list>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
          </v-content>
        </div>
      </div>
      <div class="toolbars-params">
        <h4>En este espacio puedes declarar los datos que necesitas del usuario para su registro o posterior funcionamiento.
            Agrega, elimina o cambia el orden de los parametros seleccionados
        </h4>
        <div class="toolbar-options">
            <v-icon 
            v-on:click="showModalAdd"
            class="toolbar-icon" color="#382444" large> mdi-plus </v-icon>
            <v-icon class="toolbar-icon" color="#382444" large> mdi-database-search </v-icon>
            <v-icon class="toolbar-icon" color="#382444" large> mdi-database-export </v-icon>
        </div>
      </div>
    </div>
    <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Crear parametro</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="nameModal"
                  label="Nombre del parametro*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="placeHolderModal"
                  label="Escribe como pediremos este dato (Ej: Escribe tu correo)*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                v-model="keyModal"
                  label="Key del parametro*"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-select
                v-model="isOptionalModal"
                  :items="['SI', 'NO']"
                  label="¿Es opcional? (No es forzoso para el registro)*"
                  required
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-select
                v-model="isSaveInDB"
                  :items="['SI', 'NO']"
                  label="¿Se guarda en la base de datos? (O su uso es exclusivo de logica de la app)*"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <small>*Todos los campos son necesarios</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Salir
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveNewParam"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
  </v-app>
</template>

<script>
import { Drag, DropList } from "vue-easy-dnd";
import { app } from "@/firebase";
import { getDatabase, ref, onValue, set } from "firebase/database";

export default {
  name: "App",
  components: {
    Drag,
    DropList,
  },
  data: function () {
    return {
        dialog: false,
        keyModal: "",
        nameModal: "",
        placeHolderModal: "",
        isOptionalModal: "SI",
        isSaveInDB: "SI",
      items1: [
        {
          title: "Brunch this weekend?",
          subtitle:
            "<span class='text--primary'>Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?",
        },
        {
          title: "Summer BBQ",
          subtitle:
            "<span class='text--primary'>to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.",
        },
        {
          title: "Oui oui",
          subtitle:
            "<span class='text--primary'>Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?",
        },
      ],
      list_params: [],
    };
  },
  beforeMount() {
    this.getSections();
  },
  methods: {
    insert1(event) {
      this.items1.splice(event.index, 0, event.data);
    },
    insert2(event) {
      this.items2.splice(event.index, 0, event.data);
    },
    remove(array, value) {
      let index = array.indexOf(value);
      array.splice(index, 1);
    },
    addToFirebaseParam(){

    },
    showModalAdd(){
        this.dialog = !this.dialog;
    },
    saveNewParam(){
        const booleanTransform = (value) => {
            if (value == "SI") {
                return true
            } else {
                return false
            }
        }
        let dictionary = {
            "key": this.keyModal,
            "necessary": false,
            "optional": booleanTransform(this.isOptionalModal),
            "placeHolder": this.placeHolderModal,
            "save": booleanTransform(this.isSaveInDB),
            "title": this.nameModal,
            "type": 0
        }
        this.list_params.push(dictionary);
        const db = getDatabase();
        set(ref(db, 'configurations/params_for_users'), this.list_params);
        this.keyModal = "";
        this.isOptionalModal = "SI";
        this.placeHolderModal = "";
        this.isSaveInDB = "SI";
        this.nameModal = "";
        this.dialog = false;
    },
    getSections: function () {
      const funcUpdate = (itemsArray, listOriginal) => {
        this.items1 = itemsArray;
        this.list_params = listOriginal
        console.log("Lista de params original es ", this.list_params)
      };
      const canErase = (erase) => {
        if (erase == false) {
          return "Se puede eliminar";
        } else {
          return "No se puede eliminar";
        }
      };
      const turnSiNo = (erase) => {
        if (erase == true) {
          return "Si";
        } else {
          return "No";
        }
      };
      const db = getDatabase(app);
      const starCountRef = ref(db, "configurations/params_for_users");
      onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        let array = [];
        let original = [];
        data.forEach(function (doc) {
          let item = doc;
          console.log("item es ", item);
          let dict = {
            title: item.title + " / placeholder: " + item.placeHolder,
            subtitle:
              "<span class='text--primary'>" +
              item.key +
              "</span> &mdash; " +
              canErase(item.necessary) +
              " / Es opcional: " +
              turnSiNo(item.optional) +
              " / Se almacena: " +
              turnSiNo(item.save) +
              " / tipo de parametro: " +
              item.type,
          };
          array.push(dict);
          original.push(item);
        });
        console.log("data es ", data);
        funcUpdate(array, original);
      });
    },
  },
};
</script>

<style>
.title-params {
  margin-top: 30px;
  width: 100%;
  text-align: center;
}
.container-params {
  display: flex;
  flex-direction: column;
}
.list1 {
  height: 100%;
}

.list2 {
  display: flex;
  height: 100%;
}

.chip {
  margin: 10px;
}

.drop-allowed.drop-in * {
  cursor: inherit !important;
}
.params-list-div {
    border-top: 1px solid #382444;
  width: 100%;
}
.divParams {
  flex-direction: row-reverse;
  display: flex;
  background-color: white;
  width: 100%;
  height: 80vh;
}
.toolbars-params {
    display: flex;
    flex-direction: row;
    align-items: center;
      background-color: #d0ecdc;
      padding-left: 24px;
      border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  right: 0;
  z-index: 100;
  width: 76%;
  height: 60px;
}
.toolbars-params h4 {
    padding-top: 12px;
    padding-bottom: 12px;
    margin-left: -12px;
    padding-right: 12px;
    font-size: 12px;
    text-align: center;
}
.inverse-layout {
    position: absolute;
    bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: end;
  background-color: white;
  width: 100%;
  height: 86vh;
}
.toolbar-options{
    padding-left: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #449424;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    height: 60px;
}
.toolbar-options .toolbar-icon {
    background-color: #d0ecdc;
    border-radius: 50% 50%;
    margin-right: 12px;
}
</style>
