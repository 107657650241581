<template>
  <div class="navigation-class" >
    <v-navigation-drawer
        permanent
        expand-on-hover
      >
        <v-list>
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-img src="../assets/cure_logo_mini_01.png"></v-img>
            </v-list-item-avatar>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                CURE
              </v-list-item-title>
              <v-list-item-subtitle>super admin</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list
          nav
          dense
        >
        <v-list-item link v-on:click="changeView('UserParams')">
            <v-list-item-icon>
              <v-icon>mdi-database</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Base de datos</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-folder</v-icon>
            </v-list-item-icon>
            <v-list-item-title>My Files</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Shared with me</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-star</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Starred</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <div class="component-content">
        <component :is="nameOfView">
        </component>
      </div>
  </div>
</template>

<script>
import UserParams from "../components/database/UserParams.vue"
export default {
    name: 'HomeView',
    data(){
        return {
            nameOfView: "Prueba"
        }
    },
    components: {
        UserParams
    },
    methods: {
        changeView(view) {
            this.nameOfView = view;
            console.log("View ahora es ", view);
        }
    }
}
</script>

<style>
body {
}
.navigation-class{
    display: flex;
    width: 100% !important;
    height: 100vh;
}
.component-content{
    background-color: aqua;
    width: 100%;
    height: 100vh;
}
</style>