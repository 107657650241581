// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCHLeamLWnhfeXs1KO1-qWe9DWNL9v1lN0",
  authDomain: "cure-e80f5.firebaseapp.com",
  databaseURL: "https://cure-e80f5-default-rtdb.firebaseio.com",
  projectId: "cure-e80f5",
  storageBucket: "cure-e80f5.appspot.com",
  messagingSenderId: "496291845806",
  appId: "1:496291845806:web:91ea9b20f43c8b5a8abf3f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

export {
  app,
  db
}